<template>
  <view-item-setting>
    <template #operation> </template>

    <tabs-maintain
      v-model="tabs.regionId"
      :ajax="{
        action: 'GET /enocloud/common/region'
      }"
      :props="{ value: 'code', label: 'name' }"
      :method="table.get"
    ></tabs-maintain>

    <flex-box>
      <template #default="{ height }">
        总浏览量: {{ table.tableDataCount.visitCount }} 新集数量: {{ table.tableDataCount.newCustomerCount }} 下单数量:
        {{ table.tableDataCount.dealCount }} 总金额:
        {{ table.tableDataCount.dealAmount }}
        <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging">
          <en-table-column label="网点编号" prop="tenant.id"></en-table-column>
          <en-table-column label="网点全称" prop="tenant.name"></en-table-column>
          <en-table-column label="联系人" prop="tenant.contact"></en-table-column>
          <en-table-column label="联系人手机" prop="tenant.telephone"></en-table-column>
          <en-table-column label="总浏览量" prop="visitCount"></en-table-column>
          <en-table-column label="新集数量" prop="newCustomerCount"></en-table-column>
          <en-table-column label="下单数量" prop="dealCount"></en-table-column>
          <en-table-column label="总金额" prop="dealAmount">
            <template #default="{ row }: { row: EnosprayMallDefinitions['MallSummaryDto'] }">
              {{ formatMoney(row.dealCount) }}
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'
type CustomerDto = EnosprayMallDefinitions['MallSummaryDto']
export default factory({
  props: {
    modelValue: {
      type: Object as PropType<CustomerDto>,
      require: true
    },
    loading: Boolean as PropType<boolean>,
    rules: Object
  },
  config: {
    children: {
      tabs: {
        regionId: ''
      },
      form: {
        is: 'form',
        data: {},
        async change<P extends keyof CustomerDto>(prop: P, value: any) {
          if (this.modelValue) this.modelValue[prop] = value
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/mall/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { ...this.form.data, ...this.tabs }
            }
          },
          query: {
            action: 'GET /enospray/mall/summary/region',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          tableDataCount() {
            const { regionId } = this.tabs
            if (regionId === '') {
              return {
                visitCount: this.table.data.reduce((visitCount, cur) => calculator.add(visitCount, cur.visitCount), 0),
                newCustomerCount: this.table.data.reduce((newCustomerCount, cur) => calculator.add(newCustomerCount, cur.newCustomerCount), 0),
                dealCount: this.table.data.reduce((dealCount, cur) => calculator.add(dealCount, cur.dealCount), 0),
                dealAmount: this.table.data.reduce((dealAmount, cur) => calculator.add(dealAmount, cur.dealAmount), 0)
              }
            } else {
       

              let region = this.region.data.find((item) => item.region.id.toString() === regionId)
              if (region) {
                return {
                  visitCount: region.visitCount,
                  newCustomerCount: region.newCustomerCount,
                  dealCount: region.dealCount,
                  dealAmount: region.dealAmount
                }
              } else {
                return {
                  visitCount: 0,
                  newCustomerCount: 0,
                  dealCount: 0,
                  dealAmount: 0
                }
              }
            }
          }
        }
      },
      region: {
        ajax: {
          get: {
            action: 'GET /enospray/mall/summary/region',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        }
      }
    }
  },

  async mounted() {
    this.table.get()
    this.table.query()
    this.region.get()
  }
})
</script>
